/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
import React from "react"
import { setidentifier } from "../../DataHandler/DataHandler.jsx";
import { AnaControl, ChartModel, Distrito, IAControl, RefreshMap, ResetJsonData, filterBySize, rugosidadeControl, toggleMonitoramento } from "../../index.js"
import { LegendChart, toggleLegendFilter, toggleLegendFilterByClass } from "../../Legenda/LegendChart.jsx";
import TypeFilter, { setSeparador } from "../Filter/Filter.jsx";
import ChartWithSelector from "./ChartWithSelector.jsx";
import PrepareTimeControl from "../TimeControl/timeControl.jsx";
// import { ChartRug } from "./ChartRug.jsx";

let id = 5
export function generateHTML(response, IA, jsonData) {
    let linkGraficos = response.apigraficohistorico
    let formated = []
    let html = []
    let htmlCadastro = []
    let filter = []
    for (let i = 0; i < response.filtrosregionais.length; i++) {
        if (i === 0) {
            filter.push(<li id={response.filtrosregionais[i]} key={response.filtrosregionais[i]} onClick={() => filterSelect(response.filtrosregionais[i], i)} className="filtrosSelect active" >{response.filtrosregionais[i]}</li>)
        } else {
            filter.push(
                <li id={response.filtrosregionais[i]} key={response.filtrosregionais[i]} onClick={() => filterSelect(response.filtrosregionais[i], i)} className="filtrosSelect">{response.filtrosregionais[i]}</li>
            )
        }
    }
    // filter.push(
    //     <li key="noFilter" id="noFilter" onClick={() => filterSelect("noFilter", 0)} className="filtrosSelect">Sem Filtro</li>
    // )

    const comparNumbers = () => {
        const deButton = document.getElementById("deButton")
        const ateButton = document.getElementById("ateButton")
        if (ateButton.value !== "" && deButton.value !== "") {
            if (parseInt(ateButton.value) < parseInt(deButton.value)) {
                ateButton.classList.add("active")
                document.getElementById("popupExtension").style.display = "flex"
            } else {
                ateButton.classList.remove("active")
                document.getElementById("popupExtension").style.display = "none"
            }
        } else {
            ateButton.classList.remove("active")
            document.getElementById("popupExtension").style.display = "none"
        }
    }

    html.push(
        <div id="extensionFilter" className="extensionFilter">
            <div className="centerElementsExtensionText">
                <div>Extensão da via [m]</div>
            </div>
            <div className="centerElementsExtension">
                <div className="inputExtensionCenter">
                    <div className="infoExtension">De</div>
                    <input id="deButton" type={"number"} className="inputExtension"
                        onChange={comparNumbers}
                    />
                </div>
            </div>
            <div className="centerElementsExtension">
                <div className="inputExtensionCenter">
                    <div className="infoExtension">
                        <div id="popupExtension">Este número precisa ser maior que o anterior</div>
                        Até
                    </div>
                    <input id="ateButton" type={"number"} className="inputExtension"
                        onChange={comparNumbers}
                    />
                </div>
            </div>
            <div className="filterBtn" onClick={() => filterBySize()}><i className="far fa-filter"></i></div>
        </div>
    )

    let otimo = 0
    let bom = 0
    let regular = 0
    let ruim = 0
    let pessimo = 0
    let total = 0
    let percorido = 0

    const features = IA.features;
    const list = [];

    features.forEach((feature) => {
        const label = feature.properties.ai_object_name;
        const id = feature.properties.ai_object_id;
        const rgba = `rgba(${feature.properties.ai_object_color_rgb.join(", ")})`;

        const existingItem = list.find((item) => item.label === label);
        if (!existingItem) {
            list.push({ label, id, rgba });
        }
    });

    for (let i = 0; i < response.classeprincipal.length; i++) {
        const classe = response.classeprincipal[i]
        if (classe === 'Qualidade Superficial do Pavimento') {
            if (jsonData !== undefined) {
                for (let j = 0; j < jsonData.features.length; j++) {
                    if (jsonData.features[j].properties.qualidade === "Ótimo") {
                        percorido = percorido + jsonData.features[j].properties.tamanho_trecho
                        total = total + jsonData.features[j].properties.tamanho_trecho
                        otimo = otimo + jsonData.features[j].properties.tamanho_trecho
                    } else if (jsonData.features[j].properties.qualidade === "Bom") {
                        percorido = percorido + jsonData.features[j].properties.tamanho_trecho
                        total = total + jsonData.features[j].properties.tamanho_trecho
                        bom = bom + jsonData.features[j].properties.tamanho_trecho
                    } else if (jsonData.features[j].properties.qualidade === "Regular") {
                        percorido = percorido + jsonData.features[j].properties.tamanho_trecho
                        total = total + jsonData.features[j].properties.tamanho_trecho
                        regular = regular + jsonData.features[j].properties.tamanho_trecho
                    } else if (jsonData.features[j].properties.qualidade === "Ruim") {
                        percorido = percorido + jsonData.features[j].properties.tamanho_trecho
                        total = total + jsonData.features[j].properties.tamanho_trecho
                        ruim = ruim + jsonData.features[j].properties.tamanho_trecho
                    } else if (jsonData.features[j].properties.qualidade === "Péssimo") {
                        percorido = percorido + jsonData.features[j].properties.tamanho_trecho
                        total = total + jsonData.features[j].properties.tamanho_trecho
                        pessimo = pessimo + jsonData.features[j].properties.tamanho_trecho
                    } else {
                        total = total + jsonData.features[j].properties.tamanho_trecho
                    }
                }

                otimo = parseInt(otimo)
                bom = parseInt(bom)
                regular = parseInt(regular)
                ruim = parseInt(ruim)
                pessimo = parseInt(pessimo)
                total = parseInt(total)
                percorido = parseInt(percorido)

                const objectLegendRug = [
                    {
                        "titulo": "Ótimo",
                        "cor": '#3b842f',
                        "id": 0
                    },
                    {
                        "titulo": "Bom",
                        "cor": '#88d07b',
                        "id": 1
                    },
                    {
                        "titulo": "Regular",
                        "cor": '#f7de6f',
                        "id": 2
                    },
                    {
                        "titulo": "Ruim",
                        "cor": '#fea19e',
                        "id": 3
                    },
                    {
                        "titulo": "Péssimo",
                        "cor": '#7f312f',
                        "id": 4
                    },
                ]

                html.push(
                    <div id="idRugosidade" className="SidebarContentModel" model="true/enable" value={classe} key={classe}
                        click={[() => (rugosidadeControl(true, 'idRugosidade')), () => (rugosidadeControl(false, 'idRugosidade'))]}
                    >
                        <div className="classDescription">{response.descricoesclasses[i]}</div>
                        <div className="infoSeparate"></div>
                        <div className="totalAvaliated">
                            <div className="descriptionAvaliated">
                                <div><strong>Extensão viária Total</strong></div>
                                <div>{total.toLocaleString()} m</div>
                                <div><strong>Extensão viária Avaliada</strong></div>
                                <div>{percorido.toLocaleString()} m</div>
                            </div>
                            <div className="chartAvaliated">
                                <ChartModel
                                    mode="doughnut"
                                    labels={['Percorrido', 'Restante']}
                                    datas={[percorido, total - percorido]}
                                    colors={['#646464', '#d4d4d4']}
                                    responsive={true}
                                    legend={false}
                                    counter="m"
                                    half={true}
                                />
                            </div>
                        </div>
                        <div className="infoSeparate"></div>
                        <div className="toggleMonitoramento">
                            <div className="legendFilter">
                                <label className="switch">
                                    <input type="checkbox" onClick={(e) => toggleLegendFilter(e)} defaultChecked></input>
                                    <span className="sliderButton round"></span>
                                </label>
                            </div>
                        </div>
                        <div className="rugosidadeChartInfo">Avaliação Superficial Absoluta (m)</div>
                        <div className="legendRight"
                            onMouseEnter={(e) => infoLegend(e)}
                            onMouseLeave={(e) => infoLeave(e)}
                            style={{ height: 25 * 5 + "px" }}>
                            <LegendChart objectLegend={objectLegendRug} keyProp={i + "legendChart"} mode={"rugosidadeLegend"} class={"centerRugosidadeLegend"} />
                            <div className="chartSpacing">
                                <ChartModel
                                    mode="bar"
                                    labels={['Ótimo', 'Bom', 'Regular', 'Ruim', 'Péssimo']}
                                    datas={[otimo, bom, regular, ruim, pessimo]}
                                    colors={['#3b842f', '#88d07b', '#f7de6f', '#fea19e', '#7f312f']}
                                    responsive={true}
                                    height={24 * 5 + "px"}
                                    legend={false}
                                    counter="m"
                                />
                            </div>
                        </div>
                        <div className="infoSeparate"></div>
                        <div className="rugosidadeChartInfo">Avaliação Superficial Proporcional</div>
                        <div className="chart-container">
                            <ChartModel
                                mode="doughnut"
                                labels={['Ótimo', 'Bom', 'Regular', 'Ruim', 'Péssimo']}
                                datas={[otimo, bom, regular, ruim, pessimo]}
                                colors={['#3b842f', '#88d07b', '#f7de6f', '#fea19e', '#7f312f']}
                                responsive={true}
                                legend={false}
                                counter="m"
                            />
                        </div>
                        {/* <ChartRug /> */}
                    </div>
                )
            }
        } else {
            if (IA !== undefined) {
                const colorMonTotal = []
                const quantityMonTotal = []
                const labelMonTotal = []
                const colorCadTotal = []
                const quantityCadTotal = []
                const labelCadTotal = []

                const labelMonRegAtiv = []
                const quantityMonRegAtiv = []
                const colorMonRegAtiv = []
                const labelMonRegEnc = []
                const quantityMonRegEnc = []
                const colorMonRegEnc = []

                const labelMonNaoReg = []
                const quantityMonNaoReg = []
                const colorMonNaoReg = []

                const labelCadRegAtiv = []
                const quantityCadRegAtiv = []
                const colorCadRegAtiv = []
                const labelCadRegEnc = []
                const quantityCadRegEnc = []
                const colorCadRegEnc = []

                const labelCadNaoReg = []
                const quantityCadNaoReg = []
                const colorCadNaoReg = []

                const objectLegendMonRegAtiv = []
                const objectLegendMonRegEnc = []
                const objectLegendCadRegAtiv = []
                const objectLegendCadRegEnc = []
                const objectLegendMonNaoReg = []
                const objectLegendCadNaoReg = []
                if (response.subclasseprincipal[i] === "true") {
                    for (let j = 0; j < IA.features.length; j++) {
                        if (classe === IA.features[j].properties.classeprincipal) {
                            const cor = IA.features[j].properties.ai_object_color_rgb
                            let labelDuplicated = false
                            let labelDuplicatedAux = false
                            if (IA.features[j].properties.classemae === "Monitoramento") {
                                for (let x = 0; x < labelMonTotal.length; x++) {
                                    if (labelMonTotal[x] === IA.features[j].properties.ai_object_name) {
                                        quantityMonTotal[x]++; labelDuplicatedAux = true
                                    }
                                }
                                if (!(labelDuplicatedAux)) {
                                    colorMonTotal.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                    quantityMonTotal.push(1)
                                    labelMonTotal.push(IA.features[j].properties.ai_object_name)
                                }
                                if (IA.features[j].properties.deteccao_ativa !== null) {
                                    if (IA.features[j].properties.deteccao_ativa === true) {
                                        for (let x = 0; x < labelMonRegAtiv.length; x++) {
                                            if (labelMonRegAtiv[x] === IA.features[j].properties.ai_object_name) {
                                                quantityMonRegAtiv[x]++; labelDuplicated = true
                                            }
                                        }
                                        if (!(labelDuplicated)) {
                                            colorMonRegAtiv.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                            quantityMonRegAtiv.push(1)
                                            labelMonRegAtiv.push(IA.features[j].properties.ai_object_name)
                                            const object = {
                                                "titulo": IA.features[j].properties.ai_object_name,
                                                "cor": `rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`,
                                                "id": id,
                                                "mode": IA.features[j].properties.deteccao_ativa,
                                            }
                                            objectLegendMonRegAtiv.push(object)
                                            id++
                                        }
                                    } else {
                                        for (let x = 0; x < labelMonRegEnc.length; x++) {
                                            if (labelMonRegEnc[x] === IA.features[j].properties.ai_object_name) {
                                                quantityMonRegEnc[x]++; labelDuplicated = true
                                            }
                                        }
                                        if (!(labelDuplicated)) {
                                            colorMonRegEnc.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                            quantityMonRegEnc.push(1)
                                            labelMonRegEnc.push(IA.features[j].properties.ai_object_name)
                                            const object = {
                                                "titulo": IA.features[j].properties.ai_object_name,
                                                "cor": `rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`,
                                                "id": id,
                                                "mode": IA.features[j].properties.deteccao_ativa,
                                            }
                                            objectLegendMonRegEnc.push(object)
                                            id++
                                        }
                                    }
                                } else {
                                    for (let x = 0; x < labelMonNaoReg.length; x++) {
                                        if (labelMonNaoReg[x] === IA.features[j].properties.ai_object_name) {
                                            quantityMonNaoReg[x]++; labelDuplicated = true
                                        }
                                    }
                                    if (!(labelDuplicated)) {
                                        colorMonNaoReg.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                        quantityMonNaoReg.push(1)
                                        labelMonNaoReg.push(IA.features[j].properties.ai_object_name)
                                        const object = {
                                            "titulo": IA.features[j].properties.ai_object_name,
                                            "cor": `rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`,
                                            "id": id,
                                            "mode": IA.features[j].properties.deteccao_ativa,
                                        }
                                        objectLegendMonNaoReg.push(object)
                                        id++
                                    }
                                }
                            } else {
                                for (let x = 0; x < labelCadTotal.length; x++) {
                                    if (labelCadTotal[x] === IA.features[j].properties.ai_object_name) {
                                        quantityCadTotal[x]++; labelDuplicatedAux = true
                                    }
                                }
                                if (!(labelDuplicatedAux)) {
                                    colorCadTotal.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                    quantityCadTotal.push(1)
                                    labelCadTotal.push(IA.features[j].properties.ai_object_name)
                                }
                                if (IA.features[j].properties.deteccao_ativa) {
                                    if (IA.features[j].properties.deteccao_ativa === true) {
                                        for (let x = 0; x < labelCadRegAtiv.length; x++) {
                                            if (labelCadRegAtiv[x] === IA.features[j].properties.ai_object_name) {
                                                quantityCadRegAtiv[x]++; labelDuplicated = true
                                            }
                                        }
                                        if (!(labelDuplicated)) {
                                            colorCadRegAtiv.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                            quantityCadRegAtiv.push(1)
                                            labelCadRegAtiv.push(IA.features[j].properties.ai_object_name)
                                            const object = {
                                                "titulo": IA.features[j].properties.ai_object_name,
                                                "cor": `rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`,
                                                "id": id,
                                                "mode": IA.features[j].properties.deteccao_ativa,
                                            }
                                            objectLegendCadRegAtiv.push(object)
                                            id++
                                        }
                                    } else {
                                        for (let x = 0; x < labelCadRegEnc.length; x++) {
                                            if (labelCadRegEnc[x] === IA.features[j].properties.ai_object_name) {
                                                quantityCadRegEnc[x]++; labelDuplicated = true
                                            }
                                        }
                                        if (!(labelDuplicated)) {
                                            colorCadRegEnc.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                            quantityCadRegEnc.push(1)
                                            labelCadRegEnc.push(IA.features[j].properties.ai_object_name)
                                            const object = {
                                                "titulo": IA.features[j].properties.ai_object_name,
                                                "cor": `rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`,
                                                "id": id,
                                                "mode": IA.features[j].properties.deteccao_ativa,
                                            }
                                            objectLegendCadRegEnc.push(object)
                                            id++
                                        }
                                    }
                                } else {
                                    for (let x = 0; x < labelCadNaoReg.length; x++) {
                                        if (labelCadNaoReg[x] === IA.features[j].properties.ai_object_name) {
                                            quantityCadNaoReg[x]++; labelDuplicated = true
                                        }
                                    }
                                    if (!(labelDuplicated)) {
                                        colorCadNaoReg.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                        quantityCadNaoReg.push(1)
                                        labelCadNaoReg.push(IA.features[j].properties.ai_object_name)
                                        const object = {
                                            "titulo": IA.features[j].properties.ai_object_name,
                                            "cor": `rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`,
                                            "id": id,
                                            "mode": IA.features[j].properties.deteccao_ativa,
                                        }
                                        objectLegendCadNaoReg.push(object)
                                        id++
                                    }
                                }
                            }
                        }
                    }

                    // let visibilityMon = true
                    if (quantityMonRegAtiv.length !== 0 || quantityMonNaoReg.length !== 0) {
                        html.push(
                            <div id={(classe + "Mon").replace(/\s/g, '')} className="SidebarContentModel" model="true/enable" value={classe} key={classe}
                                click={[() => (IAControl(true, classe, "Mon")), () => (IAControl(false, classe, "Mon"))]}
                            >
                                <div className="classDescription">{response.descricoesclasses[i]}</div>
                                <div className="infoSeparate"></div>
                                <div className="toggleMonitoramento">
                                    <div className="legendFilter">
                                        <label className="switch">
                                            <input type="checkbox" onClick={(e) => toggleLegendFilterByClass(e, classe.replace(/\s/g, '') + "repor")} defaultChecked></input>
                                            <span className="sliderButton round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="registerMode firstRegister">
                                    • Defeitos reportados no sistema
                                    <br />
                                    <div className="registerModeDesc">Nesta subseção estão mapeadas e classificadas apenas aqueles defeitos reportados no sistem• a de zeladoria urbana.</div>
                                </div>
                                <div className="registerMode smallFont">Ainda ativos:</div>
                                <div className={`legendRight ${classe.replace(/\s/g, '') + "repor"}`}
                                    onMouseEnter={(e) => infoLegend(e)}
                                    onMouseLeave={(e) => infoLeave(e)}
                                    style={{ height: 25 * labelMonRegAtiv.length + "px" }}>
                                    <LegendChart objectLegend={objectLegendMonRegAtiv} keyProp={i + "legendChart"} />
                                    <div className="chartSpacing">
                                        <ChartModel
                                            mode="bar"
                                            labels={labelMonRegAtiv}
                                            datas={quantityMonRegAtiv}
                                            colors={colorMonRegAtiv}
                                            responsive={true}
                                            legend={false}
                                            height={24 * labelMonRegAtiv.length + "px"}
                                            counter=" detecções"
                                        />
                                    </div>
                                </div>
                                <div className="registerMode smallFont">Já encerrados:</div>
                                <div className={`legendRight ${classe.replace(/\s/g, '') + "repor"}`}
                                    onMouseEnter={(e) => infoLegend(e)}
                                    onMouseLeave={(e) => infoLeave(e)}
                                    style={{ height: 25 * labelMonRegEnc.length + "px" }}>
                                    <LegendChart objectLegend={objectLegendMonRegEnc} keyProp={i + "legendChart"} />
                                    <div className="chartSpacing">
                                        <ChartModel
                                            mode="bar"
                                            labels={labelMonRegEnc}
                                            datas={quantityMonRegEnc}
                                            colors={colorMonRegEnc}
                                            responsive={true}
                                            legend={false}
                                            height={24 * labelMonRegEnc.length + "px"}
                                            counter=" detecções"
                                        />
                                    </div>
                                </div>
                                <div className="infoSeparate"></div>
                                <div className="toggleMonitoramento">
                                    <div className="legendFilter">
                                        <label className="switch">
                                            <input type="checkbox" onClick={(e) => toggleLegendFilterByClass(e, classe.replace(/\s/g, '') + "Nrepor")} defaultChecked></input>
                                            <span className="sliderButton round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="registerMode">
                                    • Defeitos NÃO reportados no sistema
                                    <br />
                                    <div className="registerModeDesc">Nesta subseção estão mapeadas e classificadas apenas aqueles defeitos que ainda <div className="descStrong">NÃO</div> são reportados no sistema de zeladoria urbana.</div>
                                </div>
                                <div className={`legendRight ${classe.replace(/\s/g, '') + "Nrepor"}`}
                                    onMouseEnter={(e) => infoLegend(e)}
                                    onMouseLeave={(e) => infoLeave(e)}
                                    style={{ height: 25 * labelMonNaoReg.length + "px" }}>
                                    <LegendChart objectLegend={objectLegendMonNaoReg} keyProp={i + "legendChart"} />
                                    <div className="chartSpacing">
                                        <ChartModel
                                            mode="bar"
                                            labels={labelMonNaoReg}
                                            datas={quantityMonNaoReg}
                                            colors={colorMonNaoReg}
                                            responsive={true}
                                            legend={false}
                                            height={24 * labelMonNaoReg.length + "px"}
                                            counter=" detecções"
                                        />
                                    </div>
                                </div>
                                <div className="infoSeparate"></div>
                                <div className="chart-container">
                                    <div className="chart-container-title">Série Histórica de Detecções</div>
                                    <ChartWithSelector linkGraficos={linkGraficos} options={labelMonNaoReg} list={list} />
                                </div>
                                {/* <div className="chart-container">
                                    <ChartModel
                                    mode="doughnut"
                                    labels={labelMonTotal}
                                    datas={quantityMonTotal}
                                    colors={colorMonTotal}
                                    responsive={true}
                                    legend={false}
                                    counter=" detecções"
                                />
                                </div> */}
                            </div >
                        )
                    }

                    // let visibilityCad = true
                    if (quantityCadRegAtiv.length !== 0) {
                        htmlCadastro.push(
                            <div id={(classe + "Cad").replace(/\s/g, '')} className="SidebarContentModel" model="true/enable" value={classe} key={classe}
                                click={[() => (IAControl(true, classe, "Cad")), () => (IAControl(false, classe, "Cad"))]}
                            >
                                <div className="classDescription">{response.descricoesclasses[i]}</div>
                                <div className="infoSeparate"></div>
                                <div className="toggleMonitoramento">
                                    <div className="legendFilter">
                                        <label className="switch">
                                            <input type="checkbox" onClick={(e) => toggleLegendFilter(e)} defaultChecked></input>
                                            <span className="sliderButton round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="registerMode firstRegister">
                                    • Defeitos reportados no sistema
                                    <br />
                                    <div className="registerModeDesc">Nesta subseção estão mapeadas e classificadas apenas aqueles defeitos reportados no sistema de zeladoria urbana.</div>
                                </div>
                                <div className="registerMode smallFont">Ainda ativos:</div>
                                <div className="legendRight"
                                    onMouseEnter={(e) => infoLegend(e)}
                                    onMouseLeave={(e) => infoLeave(e)}
                                    style={{ height: 25 * labelCadRegAtiv.length + "px" }}>
                                    <LegendChart objectLegend={objectLegendCadRegAtiv} keyProp={i + "legendChart"} />
                                    <div className="chartSpacing">
                                        <ChartModel
                                            mode="bar"
                                            labels={labelCadRegAtiv}
                                            datas={quantityCadRegAtiv}
                                            colors={colorCadRegAtiv}
                                            responsive={true}
                                            legend={false}
                                            height={24 * labelCadRegAtiv.length + "px"}
                                            counter=" detecções"
                                        />
                                    </div>
                                </div>
                                <div className="registerMode smallFont">Já encerrados:</div>
                                <div className="legendRight"
                                    onMouseEnter={(e) => infoLegend(e)}
                                    onMouseLeave={(e) => infoLeave(e)}
                                    style={{ height: 25 * labelCadRegEnc.length + "px" }}>
                                    <LegendChart objectLegend={objectLegendCadRegEnc} keyProp={i + "legendChart"} />
                                    <div className="chartSpacing">
                                        <ChartModel
                                            mode="bar"
                                            labels={labelCadRegEnc}
                                            datas={quantityCadRegEnc}
                                            colors={colorCadRegEnc}
                                            responsive={true}
                                            legend={false}
                                            height={24 * labelCadRegEnc.length + "px"}
                                            counter=" detecções"
                                        />
                                    </div>
                                </div>
                                <div className="infoSeparate"></div>
                                <div className="registerMode">
                                    • Defeitos NÃO reportados no sistema
                                    <br />
                                    <div className="registerModeDesc">Nesta subseção estão mapeadas e classificadas apenas aqueles defeitos que ainda <div className="descStrong">NÃO</div> são reportados no sistema de zeladoria urbana.</div>
                                </div>
                                <div className="legendRight"
                                    onMouseEnter={(e) => infoLegend(e)}
                                    onMouseLeave={(e) => infoLeave(e)}
                                    style={{ height: 25 * labelCadNaoReg.length + "px" }}>
                                    <LegendChart objectLegend={objectLegendCadNaoReg} keyProp={i + "legendChart"} />
                                    <div className="chartSpacing">
                                        <ChartModel
                                            mode="bar"
                                            labels={labelCadNaoReg}
                                            datas={quantityCadNaoReg}
                                            colors={colorCadNaoReg}
                                            responsive={true}
                                            legend={false}
                                            height={24 * labelCadNaoReg.length + "px"}
                                            counter=" detecções"
                                        />
                                    </div>
                                </div>
                                <div className="infoSeparate"></div>
                                <div className="chart-container">
                                    <div className="chart-container-title">Série Histórica de Detecções</div>
                                    <ChartWithSelector linkGraficos={linkGraficos} options={labelCadNaoReg} list={list} />
                                </div>
                                {/* <div className="chart-container">
                                    <ChartModel
                                    mode="doughnut"
                                    labels={labelCadTotal}
                                    datas={quantityCadTotal}
                                    colors={colorCadTotal}
                                    responsive={true}
                                    legend={false}
                                    counter=" detecções"
                                />
                                </div> */}
                            </div>
                        )
                    }
                } else {
                    const labelMon = []
                    const quantityMon = []
                    const colorMon = []
                    const labelCad = []
                    const quantityCad = []
                    const colorCad = []

                    const objectLegendMon = []
                    const objectLegendCad = []
                    for (let j = 0; j < IA.features.length; j++) {
                        if (classe === IA.features[j].properties.classeprincipal) {
                            const cor = IA.features[j].properties.ai_object_color_rgb
                            let labelDuplicated = false
                            if (IA.features[j].properties.classemae === "Monitoramento") {
                                for (let x = 0; x < labelMon.length; x++) {
                                    if (labelMon[x] === IA.features[j].properties.ai_object_name) {
                                        quantityMon[x]++; labelDuplicated = true
                                    }
                                }
                                if (!(labelDuplicated)) {
                                    colorMon.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                    quantityMon.push(1)
                                    labelMon.push(IA.features[j].properties.ai_object_name)
                                    const object = {
                                        "titulo": IA.features[j].properties.ai_object_name,
                                        "cor": `rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`,
                                        "id": id,
                                        "mode": IA.features[j].properties.deteccao_ativa,
                                    }
                                    objectLegendMon.push(object)
                                    id++
                                }
                            } else {
                                for (let x = 0; x < labelCad.length; x++) {
                                    if (labelCad[x] === IA.features[j].properties.ai_object_name) {
                                        quantityCad[x]++; labelDuplicated = true
                                    }
                                }
                                if (!(labelDuplicated)) {
                                    colorCad.push(`rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`)
                                    quantityCad.push(1)
                                    labelCad.push(IA.features[j].properties.ai_object_name)
                                    const object = {
                                        "titulo": IA.features[j].properties.ai_object_name,
                                        "cor": `rgba(${cor[0] + ', ' + cor[1] + ', ' + cor[2]})`,
                                        "id": id,
                                        "mode": IA.features[j].properties.deteccao_ativa,
                                    }
                                    objectLegendCad.push(object)
                                    id++
                                }
                            }
                        }
                    }

                    // let visibilityMon = true
                    if (quantityMon.length !== 0) {
                        html.push(
                            <div id={(classe + "Mon").replace(/\s/g, '')} className="SidebarContentModel" model="true/enable" value={classe} key={classe}
                                click={[() => (IAControl(true, classe, "Mon")), () => (IAControl(false, classe, "Mon"))]}
                            >
                                <div className="classDescription">{response.descricoesclasses[i]}</div>
                                <div className="infoSeparate"></div>
                                <div className="toggleMonitoramento">
                                    <div className="legendFilter up">
                                        <label className="switch">
                                            <input type="checkbox" onClick={(e) => toggleLegendFilter(e)} defaultChecked></input>
                                            <span className="sliderButton round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="legendRight"
                                    onMouseEnter={(e) => infoLegend(e)}
                                    onMouseLeave={(e) => infoLeave(e)}
                                    style={{ height: 25 * labelMon.length + "px" }}>
                                    <LegendChart objectLegend={objectLegendMon} keyProp={i + "legendChart"} />
                                    <div className="chartSpacing">
                                        <ChartModel
                                            mode="bar"
                                            labels={labelMon}
                                            datas={quantityMon}
                                            colors={colorMon}
                                            responsive={true}
                                            legend={false}
                                            height={24 * labelMon.length + "px"}
                                            counter=" detecções"
                                        />
                                    </div>
                                </div>
                                <div className="infoSeparate"></div>
                                <div className="chart-container">
                                    <div className="chart-container-title">Série Histórica de Detecções</div>
                                    <ChartWithSelector linkGraficos={linkGraficos} options={labelMon} list={list} />
                                </div>
                                {/* <div className="chart-container">
                                    <ChartModel
                                        mode="doughnut"
                                        labels={labelMon}
                                        datas={quantityMon}
                                        colors={colorMon}
                                        responsive={true}
                                        legend={false}
                                        counter=" detecções"
                                    />
                                </div> */}
                            </div>
                        )
                    }

                    // let visibilityCad = true
                    if (quantityCad.length !== 0) {
                        htmlCadastro.push(
                            <div id={(classe + "Cad").replace(/\s/g, '')} className="SidebarContentModel" model="true/enable" value={classe} key={classe}
                                click={[() => (IAControl(true, classe, "Cad")), () => (IAControl(false, classe, "Cad"))]}
                            >
                                <div className="classDescription">{response.descricoesclasses[i]}</div>
                                <div className="infoSeparate"></div>
                                <div className="toggleMonitoramento">
                                    <div className="legendFilter up">
                                        <label className="switch">
                                            <input type="checkbox" onClick={(e) => toggleLegendFilter(e)} defaultChecked></input>
                                            <span className="sliderButton round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="legendRight"
                                    onMouseEnter={(e) => infoLegend(e)}
                                    onMouseLeave={(e) => infoLeave(e)}
                                    style={{ height: 25 * labelCad.length + "px" }}>
                                    <LegendChart objectLegend={objectLegendCad} keyProp={i + "legendChart"} />
                                    <div className="chartSpacing">
                                        <ChartModel
                                            mode="bar"
                                            labels={labelCad}
                                            datas={quantityCad}
                                            colors={colorCad}
                                            responsive={true}
                                            legend={false}
                                            height={24 * labelCad.length + "px"}
                                            counter=" detecções"
                                        />
                                    </div>
                                </div>
                                <div className="infoSeparate"></div>
                                <div className="chart-container">
                                    <div className="chart-container-title">Série Histórica de Detecções</div>
                                    <ChartWithSelector linkGraficos={linkGraficos} options={labelCad} list={list} />
                                </div>
                                {/* <div className="chart-container">
                                    <ChartModel
                                        mode="doughnut"
                                        labels={labelCad}
                                        datas={quantityCad}
                                        colors={colorCad}
                                        responsive={true}
                                        legend={false}
                                        counter=" detecções"
                                    />
                                </div> */}
                            </div>
                        )
                    }
                }
            }
        }
    }

    let analises = []
    let visibilityAna = []
    if (response.analisefiltros) {
        for (let i = 0; i < response.analisefiltros.length; i++) {
            visibilityAna.push(true)
            const analisefiltros = response.analisefiltros[i]
            analises.push(
                <div id={(analisefiltros + "Ana").replace(/\s/g, '')} className="SidebarContentModel" model="true/enable" value={analisefiltros} key={analisefiltros}
                    click={[() => (AnaControl(i)), () => (AnaControl(i))]}
                >
                    <div className="classDescription">{response.descricoesanalises[i]}</div>
                    {/* <div className="infoSeparate"></div> */}
                    {/* <div className="toggleMonitoramento">
                    <div className="legendFilter up">
                        <label className="switch">
                            <input type="checkbox" onClick={(e) => toggleLegendFilter(e)} defaultChecked></input>
                            <span className="sliderButton round"></span>
                        </label>
                    </div>
                </div>
                <div className="legendRight"
                    onMouseEnter={(e) => infoLegend(e)}
                    onMouseLeave={(e) => infoLeave(e)}
                    style={{ height: 25 * labelCad.length + "px" }}>
                    <LegendChart objectLegend={objectLegendCad} keyProp={i + "legendChart"} />
                    <div className="chartSpacing">
                        <ChartModel
                            mode="bar"
                            labels={labelCad}
                            datas={quantityCad}
                            colors={colorCad}
                            responsive={true}
                            legend={false}
                            height={24 * labelCad.length + "px"}
                            counter=" detecções"
                        />
                    </div>
                </div>
                */}
                </div>
            )
        }
    }

    const abaLateral = []
    abaLateral.push(<div model="false">
        <div id="fixedFilter">
            <div id="sessionPanel1" className="barraFilter">
                <TypeFilter />
            </div>
            <div id="FilterCentral" className="FilterCentral">
                <div className="controlFilter">
                    <ul>
                        {filter}
                    </ul>
                </div>
            </div>
            <div className="timeControlCenter">
                <PrepareTimeControl predefinicoes={response}></PrepareTimeControl>
            </div>
        </div>
        <div className="toggleMonitoramento">
            <div className="toggleLegend">
                <label className="switch">
                    <input id="toggleInput" type="checkbox" onClick={(e) => toggleMonitoramento(e, "Monitoramento")} defaultChecked></input>
                    <span className="sliderButton round"></span>
                </label>
            </div>
        </div>
    </div>)
    for (let i = 0; i < response.classemae.length; i++) {
        const modo = response.classemae[i]
        if (modo === 'Monitoramento') {
            abaLateral.push(
                <div className="SidebarContentModel"
                    model="enable" icon={`far ${response.logosclassemae[i]}`} value={modo}
                    description={response.descricoesclassemae[i]}>
                    {html}
                </div>
            )
        }
        if (modo === 'Inventário') {
            abaLateral.push(
                <div model="false">
                    <div className="toggleMonitoramento">
                        <div className="toggleLegend">
                            <label className="switch">
                                <input id="toggleInput1" type="checkbox" onClick={(e) => toggleMonitoramento(e, "Inventário")}></input>
                                <span className="sliderButton round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            )
        }
        if (modo === 'Inventário') {
            abaLateral.push(
                <div className="SidebarContentModel"
                    model="enable" icon={`far ${response.logosclassemae[i]}`} value={modo}
                    description={response.descricoesclassemae[i]}>
                    {htmlCadastro}
                </div>
            )
        }
        if (modo === 'Análise') {
            abaLateral.push(
                <div className="SidebarContentModel"
                    model="enable" icon={`far ${response.logosclassemae[i]}`} value={modo}
                    description={response.descricoesclassemae[i]}>
                    {analises}
                </div>
            )
        }
    }

    formated.push(abaLateral)
    // formated.push(html)
    // formated.push(htmlCadastro)
    // formated.push(analises)
    // formated.push(filter)
    return formated
}

function filterSelect(elm, i) {
    document.querySelectorAll(".filtrosSelect").forEach(box => { box.classList.remove("active") });
    document.getElementById(elm).classList.add("active")

    setSeparador(i)
    setidentifier("nome" + elm)
    console.log('elm', elm)
    document.getElementById("typeFilter").placeholder = `Pesquisa por ${elm}`
    if (elm !== "noFilter") { RefreshMap(); }
    else { ResetJsonData(); Distrito(false) }
    RefreshMap()
    if (document.getElementById("typeFilter") !== null) { document.getElementById("typeFilter").value = "" }
}

function infoLegend(event) {
    event.persist()
    const newDiv = document.createElement('div');
    newDiv.classList.add('infoLegendBlocked');
    if (event.target.parentNode.parentNode.parentNode.querySelectorAll(".toggleSessionsSub")[0] !== undefined) {
        const newDivText = document.createElement('div');
        const icon = document.createElement('i');
        icon.classList.add('fas')
        icon.classList.add('fa-arrow-up')
        icon.classList.add('arrowIcon')
        const classeprincipal = event.target.parentNode.parentNode.parentNode.querySelectorAll(".toggleSessionsSub")[0].querySelectorAll(".gridSessionsEnable")[0].textContent
        const text = `Ative este modo de visualização indo no início desta classe e habilitando ${classeprincipal} para conseguir interagir.`;
        const replacedText = text.replace(classeprincipal, `<strong>${classeprincipal}</strong>`);
        newDivText.innerHTML = replacedText;
        newDiv.appendChild(newDivText);
        newDiv.appendChild(icon);
        event.target.appendChild(newDiv);
        setTimeout(() => { newDiv.classList.add('active'); }, 100);
    }
}

function infoLeave() {
    document.querySelectorAll(".infoLegendBlocked").forEach(element => {
        element.classList.remove("active");
        setTimeout(() => { element.remove(); }, 300);
    });
}

